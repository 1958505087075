<template>
  <div class="div-padding" id="usecase">
    <b-container class="no-padding-mobile mb-5">
      <b-row class="text-center text-left pt-5 pl-lg-5 pr-lg-0 wrapper d-none">
        <b-col>
          <div class="text-1 text-center-mobile">
            {{ $t("website.pricing.tverify.pricingcalculator.title") }}
          </div>
          <b-row class="text-center te-left">
            <b-col
              lg="8"
              style="
                background: #fefefe;
                mix-blend-mode: normal;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
              "
              class="mt-5"
            >
              <div class="text-center text-lg-left p-5">
                <span class="text-2">{{
                  $t("website.pricing.tverify.pricingcalculator.subtitle")
                }}</span>
                <div class="position-relative mt-3">
                  <ul class="nav margin-left-center-mobile">
                    <li
                      v-for="(tabContent, index) in tabContents"
                      :key="`${index}`"
                      :id="`nav-tab-${index}`"
                      :data-id="`${index}`"
                      :class="`nav-item tab-bottom tab-text text-center ${activeTabColor(
                        index
                      )}`"
                      :style="`border: 1px solid #EFEFEF; width: ${tabContent.tabwidths}%`"
                      @click="onTabClick"
                      @mouseenter="onTabMouseEnter"
                      @mouseleave="onTabMouseLeave"
                    >
                      <div class="nav-link" href="#" :data-id="`${index}`">
                        <img
                          style="width: 70px"
                          :src="require(`../assets/img/${tabContent.images}`)"
                          :data-id="`${index}`"
                        />
                        {{ tabContent.tab }}
                      </div>
                    </li>
                  </ul>
                  <div
                    class="line"
                    :style="{
                      left: `${lineStyles.left}px`,
                      width: `${lineStyles.width}px`,
                    }"
                  ></div>
                </div>
                <div
                  class="mb-5 text-center mr-1 p-5"
                  style="background: #faf8f8"
                  v-html="tabContents[currentTab].description"
                ></div>
                <div class="my-5 row">
                  <div class="col-9 text-2 mt-3">
                    {{
                      $t("website.pricing.tverify.pricingcalculator.heading1")
                    }}
                  </div>
                  <div class="col-3">
                    <b-input-group size="sm">
                      <b-form-input
                        class="py-4 text-center text-3"
                        value="500"
                        v-model="value"
                        maxlength="5"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="col-12 mt-4">
                    <b-input-group prepend="0" append="1000">
                      <b-form-input
                        type="range"
                        min="0"
                        max="1000"
                        v-model="value"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              lg="4"
              style="
                background: #fefefe;
                mix-blend-mode: normal;
                box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
              "
              class="mt-5"
            >
              <div class="p-3 text-center">
                <img
                  style="width: 70px"
                  :src="
                    require(`../assets/img/${tabContents[currentTab].images}`)
                  "
                /><span>{{ tabContents[currentTab].tab }}</span>
              </div>
              <div class="p-3" style="border-top: 1px solid #efefef">
                <div class="text-4 mb-3">
                  {{ $t("website.pricing.tverify.pricingcalculator.heading2") }}
                </div>
                <div class="text-5 mb-3">
                  {{ $t("website.pricing.tverify.pricingcalculator.heading3") }}
                </div>
                <div class="row mb-5 pb-5">
                  <div class="text-6 col-8 pt-1" style="text-align: start">
                    MYR {{ tabContents[currentTab].price }}
                  </div>
                  <div class="col-4">
                    <div class="row">
                      <div class="col-5 px-0 text-right pt-1">
                        <span class="text-6">x</span>
                      </div>
                      <div class="col-7 mb-5 px-0">
                        <b-form-input
                          class="px-0 pt-1"
                          v-model="value"
                          style="border: 0px; background: none; text-align: end"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-7 mt-5 pt-5">
                  {{ $t("website.pricing.tverify.pricingcalculator.note") }}
                </div>
              </div>
              <div
                class="p-3 text-center"
                style="border-top: 1px solid #efefef"
              >
                <div class="row">
                  <div class="col-6 text-left">
                    <span class="text-4">{{
                      $t("website.pricing.tverify.pricingcalculator.amount")
                    }}</span>
                  </div>
                  <div class="col-6 text-right text-8">
                    MYR <span v-text="total"></span>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col
              lg="12"
              style="background: #fefefe; mix-blend-mode: normal"
              class="mt-5"
            >
              <span class="side-bullet"
                ><img style="width: 1.2rem" src="../assets/img/customprice.png"
              /></span>
              <span class="text-9">
                {{ $t("website.pricing.tverify.pricingcalculator.custom") }}
              </span>
              <a href="/contactus" class="text-10"
                >{{ $t("website.pricing.tverify.pricingcalculator.salesbtn") }}
                <img
                  style="width: 1.2rem"
                  src="../assets/img/arrow-right-blue.png"
              /></a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.text-1 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 52px;
}

.text-2 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
}

.text-3 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.text-4 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.text-5 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
}

.text-6 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.text-7 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
}

.text-8 {
  color: #5c52ca;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
}

.text-9 {
  color: #4f4f4f;
  font-family: Jost;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

.text-10 {
  color: #5c52ca;
  font-family: Jost;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.line {
  position: absolute;
  bottom: 0;
  transition: left 0.5s ease-in-out, width 0.5s 0.1s;
  background-color: #ff3377;
  left: 0;
  width: 130px;
  height: 6px;
  pointer-events: none;
}

.text-color-blue {
  color: #ff3377 !important;
}

.tab-text {
  color: #bdbdbd;
}

.tab-bottom {
  border-bottom: 3px solid #c4c4c4 !important;
}

.side-bullet {
  background-color: rgba(176, 168, 246, 0.1);
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  text-align: center;
  @media screen and (max-width: 576px) {
    max-width: 65px;
  }
}
</style>

<script>
/* Vue Section */
// import TekkisButton from "./buttons/TekkisButton.vue";

export default {
  components: {
    // TekkisButton,
  },
  computed: {
    lineStyles() {
      return this.lineTemp == null ? this.line : this.lineTemp;
    },
    activeTabColor() {
      if (this.hoverTab !== null)
        return (tab) => (tab == this.hoverTab ? "text-color-blue" : "");

      return (tab) => (tab == this.currentTab ? "text-color-blue" : "");
    },
    total: function () {
      var price = this.tabContents[this.currentTab].price;
      var total = this.value * price;
      var num = total.toFixed(2);
      var num_parts = num.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    },
  },
  mounted() {
    this.updateLine(0);
  },
  data() {
    return {
      value: 500,
      currentTab: 0,
      timer: "",
      hoverTab: null,
      line: {
        left: 0,
        width: 69,
      },
      lineTemp: null,
      tabContents: [
        {
          tabwidths: "33",
          images: "logo-tverify.png",
          tab: " Integrated",
          title: "SEE THE REAL IDENTITY.",
          price: "1.50",
          description:
            "<span style='color: #4f4f4f;font-family: Jost;font-style: normal;font-weight: 400;font-size: 36px;line-height: 52px;'>RM 1.50</span><br><span style='color: #8a8a8a;font-family: Jost;font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;'>per API call</span>",
        },
        {
          tabwidths: "33",
          tab: "",
          images: "logo-tverifyid.png",
          title: "ACCEPT PAYMENTS ONLINE.",
          price: "0.85",
          description:
            "<span style='color: #4f4f4f;font-family: Jost;font-style: normal;font-weight: 400;font-size: 36px;line-height: 52px;'>RM 0.85</span><br><span style='color: #8a8a8a;font-family: Jost;font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;'>per API call</span>",
        },
        {
          tabwidths: "33",
          tab: "",
          images: "logo-tverifyface.png",
          title: "ACCEPT PAYMENTS ONLINE.",
          price: "0.95",
          description:
            "<span style='color: #4f4f4f;font-family: Jost;font-style: normal;font-weight: 400;font-size: 36px;line-height: 52px;'>RM 0.95</span><br><span style='color: #8a8a8a;font-family: Jost;font-style: normal;font-weight: 400;font-size: 14px;line-height: 20px;'>per API call</span>",
        },
      ],
    };
  },
  created() {
    // this.timer = setInterval(this.refreshTab, 5000);
  },
  methods: {
    refreshTab() {
      if (this.currentTab == this.tabContents.length - 1) {
        this.currentTab = 0;
        this.updateLine(this.currentTab);
        return;
      }

      this.currentTab = parseInt(this.currentTab) + 1;
      this.updateLine(this.currentTab);
    },
    updateLine(tabNumber) {
      const tab = document.getElementById("nav-tab-" + tabNumber);
      if (tab) {
        let bottomLineStyle = {
          left: tab.offsetLeft,
          width: tab.clientWidth,
        };
        this.line = bottomLineStyle;
      }
    },
    onTabClick(evt) {
      const tab = evt.target;
      this.currentTab = tab.dataset.id;
      let bottomLineStyle = {
        left: tab.offsetLeft,
        width: tab.clientWidth,
      };
      this.line = bottomLineStyle;
    },
    onTabMouseEnter(evt) {
      const tab = evt.target;
      let bottomLineStyle = {
        left: tab.offsetLeft,
        width: tab.clientWidth,
      };
      this.lineTemp = bottomLineStyle;
      this.hoverTab = tab.dataset.id;
    },
    onTabMouseLeave() {
      this.lineTemp = null;
      this.hoverTab = null;
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>
