<template>
  <div class="pricing" id="pricing-pg">
    <PricingDashboard />
    <PricingPrice id="pricing-price" />
    <PricingFeatures />
    <PricingCalculator />
    <GetStarted />
    <!-- <SolutionTpay /> -->
  </div>
</template>

<script>
import PricingDashboard from "../components/PricingDashboard.vue";
import PricingPrice from "../components/PricingPrice.vue";
import PricingFeatures from "../components/PricingFeatures.vue";
import PricingCalculator from "../components/PricingCalculator.vue";
import GetStarted from "../components/GetStarted";
// import SolutionTpay from "../components/SolutionTpay.vue";

export default {
  name: "Pricing",
  components: {
    PricingDashboard,
    PricingPrice,
    PricingFeatures,
    PricingCalculator,
    // SolutionTpay,
    GetStarted,
  },
  data() {
    return {
      title: "Tekkis Payment Solutions - Pricing",
      description:
        "No subscription, or hidden fees. Only pay when you make a sale. We now offer ShopeePay and Auto Recurring Payment! Custom solutions and volume pricing available. Get started today!",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "No subscription, or hidden fees. Only pay when you make a sale. We now offer ShopeePay and Auto Recurring Payment! Custom solutions and volume pricing available. Get started today!";
    },
  },
};
</script>
